dmx.Formatters('number', {

    // inRange(min:Number, max:Number):Boolean
    inRange: function(number, min, max) {
        return number >= min && number <= max;
    },

    // abs():Number
    abs: function(number) {
        return Math.abs(number);
    },

    // ceil():Number
    ceil: function(number) {
        return Math.ceil(number);
    },

    // floor():Number
    floor: function(number) {
        return Math.floor(number);
    },

    // max(max:Number):Number
    max: function(number, max) {
        return Math.max(number, max);
    },

    // min(min:Number):Number
    min: function(number, min) {
        return Math.min(number, min);
    },

    // pow(exponent:Number):Number
    pow: function(number, exponent) {
        return Math.pow(number, exponent);
    },

    // round([precision:Number]):Number
    round: function(number, precision) {
        var factor = Math.pow(10, precision || 0);
        var temp = number * factor;
        var rounded = Math.round(temp);
        return rounded / factor;
    },

    // pad(length:Number):String
    pad: function(number, length) {
        var neg = number < 0 ? '-' : '';
        var str = String(Math.abs(number));
        while (str.length < length) {
            str = '0' + str;
        }
        return neg + str;
    },

    // toFixed([decimals:Number]):String
    toFixed: function(number, decimals) {
        return number.toFixed(decimals);
    },

    // formatNumber([decimals:Number], [separator:String], [delimiter:String]):String
    formatNumber: function(number, decimals, separator, delimiter) {
        if (isNaN(number)) return 'Invalid Number';
        if (!isFinite(number)) return (number < 0 ? '-' : '') + 'infinite';

        separator = separator || '.';
        delimiter = delimiter || '';

        var neg = number < 0;
        number = Math.abs(number);
        var x = (decimals != null && decimals >= 0 ? number.toFixed(decimals) : number.toString()).split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? separator + x[1] : '';

        if (delimiter) {
            var re = /(\d+)(\d{3})/;
            while (re.test(x1)) {
                x1 = x1.replace(re, '$1' + delimiter + '$2');
            }
        }

        return (neg ? '-' : '') + x1 + x2;
    },

    // formatCurrency([unit:String], [separator:String], [delimiter:String], [precision:Number]):String
    formatCurrency: function(number, unit, separator, delimiter, precision) {
        if (isNaN(number) || !isFinite(number)) return 'Invalid Amount';

        unit = unit || '$';
        separator = separator || '.';
        delimiter = delimiter || ',';
        precision = precision != null ? precision : 2;

        var neg = number < 0;
        var x = Math.abs(number).toFixed(precision).split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? separator + x[1] : '';

        if (delimiter) {
            var re = /(\d+)(\d{3})/;
            while (re.test(x1)) {
                x1 = x1.replace(re, '$1' + delimiter + '$2');
            }
        }

        return (neg ? '-' : '') + unit + x1 + x2;
    },

    // formatSize([decimals:Number], [binary:Boolean]):String
    formatSize: function(number, decimals, binary) {
        if (isNaN(number) || !isFinite(number)) return 'Invalid Size';

        decimals = decimals || 2;

        var base = binary ? 1024 : 1000;
        var suffix = binary ? ['KiB', 'MiB', 'GiB', 'TiB'] : ['KB', 'MB', 'GB', 'TB'];

        for (var i = 3; i >= 0; i--) {
            var n = Math.pow(base, i + 1);
            if (number >= n) {
                number /= n;
                var pow = Math.pow(10, decimals);
                number = Math.round(number * pow) / pow;
                return number + suffix[i];
            }
        }

        return number + 'B';
    },

    // default():Number
    default: function(number) {
        return number;
    },

    // toString():String
    toString: function(number) {
        return String(number);
    },

    // toDate():String
    toDate: function(number) {
        return (new Date(number * 1000)).toISOString();
    }

    // TODO: use localization, Intl from browser? (polyfill => https://github.com/andyearnshaw/Intl.js/)
    // format, toString, currency, toLocale, bytes

});
