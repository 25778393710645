dmx.Formatters('array', {

    // hasItems():Boolean
    hasItems: function(array) {
        return !!array.length;
    },

    // contains(value:Any):Boolean
    contains: function(array, value) {
        return array.indexOf(value) > -1;
    },

    // join(separator:String):String
    join: function(array, separator) {
        return array.join(separator);
    },

    // count():Number
    count: function(array) {
        return array.length;
    },

    // top(count:Number):Array
    top: function(array, count) {
        return array.slice(0, count);
    },

    // last(count:Number):Array
    last: function(array, count) {
        return array.slice(-count);
    },

    // slice([begin:Number], [end:Number])
    slice: function(array, begin, end) {
        return array.slice(begin, end);
    },

    // reverse():Array
    reverse: function(array) {
        return array.slice(0).reverse();
    },

    // randomize():Array
    randomize: function(array) {
        var rnd = dmx.randomizer(this.seed * dmx.hashCode(array));
        var i = array.length, t, r;

        array = array.slice(0);

        while (0 !== i) {
            r = Math.floor(rnd() * i--);
            t = array[i];
            array[i] = array[r];
            array[r] = t;
        }

        return array;
    },

    // Collection formatters (requires array with objects in them)

    // filter(expression:Expression):Array
    filter: function(array, expression) {
        return array.filter(function(item) {
            return dmx.parse(expression, new dmx.DataScope(item, this));
        }, this);
    },

    // map(expression:Expression):Array
    map: function(array, expression) {
        return array.map(function(item) {
            return dmx.parse(expression, new dmx.DataScope(item, this));
        }, this);
    },

    // where(prop:String, value:String, [operator:String]):Array
    where: function(array, prop, value, operator) {
        operator = operator || '==';

        return array.filter(function(item) {
            var val = dmx.parse(prop, new dmx.DataScope(item, this));

            switch (operator) {
                case 'startsWith': return String(val).indexOf(value) === 0;
                case 'endsWith': return String(val).substr(-value.length) === value;
                case 'contains': return String(val).indexOf(value) !== -1;
                case 'inArray': return value.indexOf(val) !== -1;
                case 'fuzzySearch': return (function(string, search) {
                    var stringLen = string.length;
                    var searchLen = search.length;
                    if (searchLen > stringLen) {
                        return false;
                    }
                    if (searchLen === stringLen) {
                        return string === search;
                    }
                    outer: for (var i = 0, j = 0; i < searchLen; i++) {
                        var char = search.charCodeAt(i);
                        while (j < stringLen) {
                            if (string.charCodeAt(j++) === char) {
                                continue outer;
                            }
                        }
                        return false;
                    }
                    return true;
                })(String(val), value);
                case '==': return val == value;
                case '===': return val === value;
                case '!=': return val != value;
                case '!==': return val !== value;
                case '<': return val < value;
                case '<=': return val <= value;
                case '>': return val > value;
                case '>=': return val >= value;
            }

            return true;
        }, this);
    },

    // values(prop:String):Array
    values: function(array, prop) {
        return array.map(function(item) {
            return dmx.parse(prop, new dmx.DataScope(item, this));
        });
    },

    // groupBy(prop:String):Object
    groupBy: function(array, prop) {
        return array.reduce(function(obj, item) {
            var key = dmx.parse(prop, new dmx.DataScope(item, this));
            if (!obj[key]) obj[key] = [];
            obj[key].push(item);
            return obj;
        }, {});
    },

    // unique([prop:String]):Array
    unique: function(array, prop) {
        if (prop) {
            array = array.map(function(item) {
                return dmx.parse(prop, new dmx.DataScope(item, this));
            });
        }

        return Object.keys(array.reduce(function(obj, value) {
            obj[JSON.stringify(value)] = 1;
            return obj;
        }, {})).map(function(key) {
            return JSON.parse(key);
        });
    },

    // sortOn([prop:String]):Array
    sort: function(array, prop) {
        return array.slice(0).sort(function(a, b) {
            if (prop) {
                a = dmx.parse(prop, new dmx.DataScope(a, this));
                b = dmx.parse(prop, new dmx.DataScope(b, this));
            }
            return a < b ? -1 : a > b ? 1 : 0;
        });
    },

    // min([prop:String]):Number
    min: function(array, prop) {
        return array.reduce(function(min, value) {
            if (prop) {
                value = dmx.parse(prop, new dmx.DataScope(value, this));
            }
            value = Number(value);
            return value < min ? value : min;
        }, Infinity);
    },

    // max([prop:String]):Number
    max: function(array, prop) {
        return array.reduce(function(max, value) {
            if (prop) {
                value = dmx.parse(prop, new dmx.DataScope(value, this));
            }
            value = Number(value);
            return value > max ? value : max;
        }, -Infinity);
    },

    // sum([prop:String]):Number
    sum: function(array, prop) {
        return array.reduce(function(sum, value) {
            if (prop) {
                value = dmx.parse(prop, new dmx.DataScope(value, this));
            }
            value = Number(value);
            return sum + value;
        }, 0);
    },

    // avg([prop:String]):Number
    avg: function(array, prop) {
        return array.reduce(function(sum, value) {
            if (prop) {
                value = dmx.parse(prop, new dmx.DataScope(value, this));
            }
            value = Number(value);
            return sum + value;
        }, 0) / array.length;
    },

    // default():Array
    default: function(array) {
        return array;
    }

});
